import { useMotion } from "@vueuse/motion";
import { useAnimation } from "./useAnimation";

export function useFromLeft(refElement, delay = 0) {
  const { createSpring } = useAnimation();

  const setupMotion = () => {
    const motionInstance = useMotion(refElement, {
      initial: {
        x: -100,
        opacity: 0,
      },
      enter: {
        x: 0,
        opacity: 1,
        transition: createSpring(300, 20, delay),
      },
    });
    return motionInstance;
  };

  return { setupMotion };
}
