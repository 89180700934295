<template>
  <q-intersection once style="min-height: 51px" @visibility="onVisible()">
    <q-btn
      ref="motionRef"
      color="info"
      :label="altLabel ? t('cta.labelAlt') : t('cta.label')"
      no-caps
      size="lg"
      text-color="dark"
      unelevated
      @click="onClick"
      @mouseenter="onMouseEnter()"
      @mouseleave="onMouseLeave()"
    />
  </q-intersection>
</template>

<script setup>
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { i18n } from "src/boot/i18n";
import { useFromLeftHover } from "src/composables/animation/useFromLeftHover";
import { useDialogStore } from "src/stores/dialog";
import { usePreferencesStore } from "src/stores/preferences";

defineOptions({ name: "DialogButton" });

const props = defineProps({
  altLabel: { type: Boolean, default: false },
});

const { t } = i18n.global;
const dialogStore = useDialogStore();
const preferencesStore = usePreferencesStore();
const { motion } = storeToRefs(preferencesStore);
const motionRef = ref(null);
const motionInstance = ref(null);

const { setupMotion } = useFromLeftHover(motionRef);

const onClick = () => {
  dialogStore.show("contact", {});
};

const onMouseEnter = () => {
  if (motion.value && motionInstance.value) motionInstance.value.apply("hover");
};

const onMouseLeave = () => {
  if (motion.value && motionInstance.value) motionInstance.value.apply("leave");
};

const onVisible = () => {
  if (motion.value) {
    motionInstance.value = setupMotion();
    motionInstance.value.apply("enter");
  }
};
</script>
