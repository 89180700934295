<template>
  <q-img
    class="fit"
    :height="height ? height : null"
    :placeholder-src="placeholderUrl"
    :src="imageUrl"
  >
    <template #loading>
      <q-spinner v-if="motion" color="info" size="2em" />
      <div v-else :class="textColor">{{ t("image.loading") }}</div>
    </template>
    <slot />
  </q-img>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { i18n } from "src/boot/i18n";
import { urlFor } from "src/boot/sanityUrlFor";
import { isColorDark } from "src/composables/useRandomPalette";
import { useResponsiveImageWidth } from "src/composables/useResponsiveImageWidth";
import { usePreferencesStore } from "src/stores/preferences";
import { useSettingsStore } from "src/stores/settings";

defineOptions({ name: "ImageComponent" });

const props = defineProps({
  image: { type: Object, default: () => {} },
  height: { type: [String, Boolean], default: false },
  width: { type: Number, default: 960 },
});

const { t } = i18n.global;
const responsiveWidth = useResponsiveImageWidth(props.width);
const preferencesStore = usePreferencesStore();
const { motion } = storeToRefs(preferencesStore);
const settingsStore = useSettingsStore();
const { selectedPalette } = storeToRefs(settingsStore);
const textColor = ref("dark");

const imageUrl = computed(() =>
  urlFor(props.image.asset.url).width(responsiveWidth.value).url()
);
const placeholderUrl = computed(() => props.image.asset?.metadata?.lqip);

const handleTextColor = () => {
  textColor.value = isColorDark(selectedPalette.value.primary?.hex || "#000000")
    ? "white"
    : "dark";
};

watch(selectedPalette, handleTextColor, { deep: true });
</script>
