import { computed } from "vue";
import { usePreferredReducedMotion } from "@vueuse/core";
import { defineStore } from "pinia";

export const usePreferencesStore = defineStore("preferences", () => {
  const preferredMotion = usePreferredReducedMotion();
  const motion = computed(() => preferredMotion.value === "no-preference");

  return {
    motion,
    preferredMotion,
  };
});
