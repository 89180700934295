import { useMotion } from "@vueuse/motion";
import { useAnimation } from "./useAnimation";

export function useFromLeftHover(
  refElement,
  xInitial = -100,
  xEnter = 0,
  delay = 400
) {
  const { spring, createSpring } = useAnimation();

  const setupMotion = () => {
    const motionInstance = useMotion(refElement, {
      initial: {
        x: xInitial,
        opacity: 0,
      },
      enter: {
        x: xEnter,
        opacity: 1,
        transition: createSpring(300, 20, delay),
      },
      hover: {
        scale: 1.1,
        transition: spring,
      },
      leave: {
        scale: 1,
        transition: spring,
      },
    });
    return motionInstance;
  };

  return { setupMotion };
}
